<template>
  <div class="container d-flex flex-column">
    <div class="row mt-5">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100 mt-5">
        <div class="d-table-cell">
          <div class="text-center mt-5">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Password Dimenticata</h3>
                <div
                  :class="[
                    'alert',
                    submitResult ? 'alert-success' : 'alert-danger',
                  ]"
                  v-if="isSubmitted"
                >
                  {{ axiosMessage }}
                </div>
                <p class="fs-6" v-else>
                  Inserisci il tuo indirizzo email, ti verrà inviata una nuova
                  password.
                </p>
                <el-form @submit.prevent="onSubmit" label-position="top">
                  <el-form-item
                    prop="email"
                    label="Email"
                    :error="emailField.errorMessage"
                  >
                    <el-input
                      ref="email"
                      :model-value="emailField.value"
                      @update:model-value="emailField.handleChange"
                      placeholder="Email"
                      name="email"
                      type="text"
                      tabindex="1"
                      autocomplete="on"
                    /> </el-form-item
                  ><el-button type="primary" native-type="submit">
                    Invia
                  </el-button>
                </el-form>
                <div v-if="submitResult">
                  <el-button type="text" @click="returnToLogin"
                    >Torna al login</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
import { PasswordForgot } from "../api/modifyData";
export default {
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();

    const emailField = reactive(
      useField(
        "email",
        yup
          .string()
          .required("Campo richiesto")
          .email("Inserire un'email valida")
      )
    );

    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      emailField,
    };
  },
  data() {
    return {
      onSubmit: this.handleSubmit(this.handlepasswordForgot),
      isSubmitted: false,
      submitResult: false,
      axiosMessage: "",
    };
  },
  methods: {
     async returnToLogin() {
          this.$router.push("/");
    },
    async handlepasswordForgot(formValues) {
      await PasswordForgot(formValues.email)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosMessage =
            "Email inviata con successo. Controlla la tua posta elettronica!";
        })
        .catch(() => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosMessage = "C'è stato un problema con l'invio. Riprova!";
        });
    },
  },
};
</script>

<style>
</style>